<template>
  <div class="editMap">
    <div :id="id" class="definedMap"></div>
    <header>
      <div class="btns">
        <el-button
          v-show="locationType == 1 ? true : false"
          type="primary"
          size="small"
          @click="drawMap"
          >绘制区域</el-button
        >
        <el-button
          v-show="locationType == 1 ? true : false"
          type="primary"
          size="small"
          @click="mapEdit"
          >编辑区域</el-button
        >
        <el-button
          v-show="locationType == 1 ? true : false"
          type="primary"
          size="small"
          class="clearMap"
          @click="clearFUN"
          >清除区域</el-button
        >
      </div>
      <div class="right">
        <!-- 附近区域下拉选择框 -->
        <div v-if="locationType == 1" class="selsect-area">
          <span class="tips">显示附近区域</span>
          <el-select
            v-model="areaValue"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
            value-key="id"
            @change="nearbyArearChange"
          >
            <el-option
              v-for="item in list"
              :label="item.name"
              :value="item"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="searchBox">
          <i class="iconfont its_gy_sousuo"></i>
          <el-input
            id="tipinput"
            v-model="searchInput"
            type="text"
            clearable
            placeholder="输入搜索地址"
          ></el-input>
          <div class="searchContent">
            <div
              v-for="(item, index) in searchList"
              :key="index"
              class="content-address"
              @click="searchClick(item)"
            >
              <span class="address">{{ item.name }}</span>
              <span class="addressDetail"
                >{{ `${item.pname}${item.cityname}${item.address}` }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import AMap from "AMap";
export default {
  props: {
    areaContent: {
      type: String,
    },
    station: {
      type: String,
    },
    id: {
      type: String,
    },
    locationType: {
      type: Number,
      default: 1, // 1-区域 2-点位
    },
    title: {
      type: String,
    },
    addForm2Copy: {
      type: Object,
    },
    areaContentList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      searchList: [],
      searchInput: "",
      centerPointMap: [], // 地图中心点数组
      AMapUI: null,
      rectangleEditor: null,
      polyEditor: null,
      mouseTool: null,
      polygon: null,
      rectangle: null,
      circle: null,
      color: "#66B1FF",
      map: null,
      form: {
        area: null,
        areaType: 3,
      },
      pickerPosition: null,
      marker: undefined,
      areaValue: [],
      markerOverlay: null,
      overlayGroup: null, //附近区域地图实例
      list: [],
    };
  },
  methods: {
    clearFUN () {
      this.form.area = null;
      this.$emit("areaContent", true);
      this.polygon && this.map.remove(this.polygon);
      this.marker && this.map.remove(this.marker);
      this.polyEditor && this.polyEditor.close();
    },
    searchClick (item) {
      this.map.setCenter([item.location.lng, item.location.lat]);
      this.map.setZoom(16);
      this.searchList = [];
      this.searchInput = "";
    },
    searchFun (val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: vm.station,
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
          }
        });
      });
    },

    /** 新增 ————画图 */
    drawMap () {
      this.form.area = null;
      this.$emit("areaContent", true);
      let self = this;
      this.form.area = "";
      this.polygon && this.map.remove(this.polygon);
      this.marker && this.map.remove(this.marker);
      this.polyEditor && this.polyEditor.close();
      this.mouseTool && this.mouseTool.close();
      this.map.plugin(["AMap.MouseTool"], function () {
        self.mouseTool = new AMap.MouseTool(self.map);
        self.mouseTool.polygon({
          strokeColor: "#336FFE",
          strokeWeight: 4,
          strokeOpacity: 0.8,
          fillColor: "#336FFE",
          fillOpacity: 0.3,
          strokeStyle: "solid",
        });
        // 监听draw件可获取画好的覆盖物
        self.mouseTool.on("draw", function (e) {
          self.getPath(e.obj.getPath(), e.obj.getArea());
          self.polygon = new AMap.Polygon({
            path: e.obj.getPath().map((e) => [e.lng, e.lat]),
            strokeColor: "#336FFE",
            strokeWeight: 4,
            strokeOpacity: 0.8,
            fillColor: "#336FFE",
            fillOpacity: 0.3,
            strokeStyle: "solid",
          });
          self.map.add(self.polygon);
          self.mouseTool.close(true);
        });
      });
    },
    /** 编辑 */
    mapEdit () {
      if (this.form.area || this.areaContent) {
        this.polygon && this.map.remove(this.polygon);
        this.marker && this.map.remove(this.marker);
        this.polyEditor && this.polyEditor.close();
        this.editPolyline();
      }
    },
    /** 处理经纬度数据 */
    getPath (arr, area) {
      this.form.area = arr.map((item) => {
        return { lng: item.lng, lat: item.lat };
      });

      this.$emit("changeAreaContent", { path: this.form.area, area });
    },
    /** 编辑路径 */
    editPolyline () {
      let self = this;
      var path = [];
      if (this.form.area) {
        path = this.form.area.map((item) => {
          return [item.lng, item.lat];
        });
      } else {
        let arr = JSON.parse(this.areaContent);
        arr.forEach((item) => {
          path.push([item.lng, item.lat]);
        });
      }
      var polygon = new AMap.Polygon({
        path: path,
        strokeColor: "#336FFE",
        strokeWeight: 4,
        strokeOpacity: 0.8,
        fillOpacity: 0.3,
        fillColor: "#336FFE",
        zIndex: 50,
      });
      this.map.add(polygon);
      this.polygon = polygon;
      this.map.setFitView([polygon]);
      this.polyEditor = new AMap.PolyEditor(this.map, polygon);
      this.polyEditor.open();
      this.polyEditor.on("adjust", function (e) {
        self.getPath(self.polygon.getPath(), self.polygon.getArea());
      });
      this.polyEditor.on("addnode", function (e) {
        self.getPath(self.polygon.getPath(), self.polygon.getArea());
      });
    },

    /** 加载地图和相关组件 */
    loadmap () {
      this.mapShow();
    },

    // 区域回显
    mapShow () {
      var self = this;
      this.clear();
      var path = [];
      if (this.areaContent == "") {
        //新增走这里
        var keywords = self.station;
        AMap.plugin("AMap.PlaceSearch", function () {
          var autoOptions = {
            city: self.station,
          };
          var placeSearch = new AMap.PlaceSearch(autoOptions);
          placeSearch.search(keywords, function (status, result) {
            self.map.setCenter([
              result.poiList.pois[0].location.lng,
              result.poiList.pois[0].location.lat,
            ]);
          });
        });
        this.$nextTick(() => {
          this.nearbyArearChange(this.list);//展示全部附近站点
        });
        return;
      }
      let arr = JSON.parse(this.areaContent);
      arr.forEach((item) => {
        path.push([item.lng, item.lat]);
      });
      var polygon = new AMap.Polygon({
        path: path,
        strokeColor: "#336FFE",
        strokeWeight: 4,
        strokeOpacity: 0.8,
        fillOpacity: 0.3,
        fillColor: "#336FFE",
        zIndex: 50,
      });
      this.map.add(polygon);
      this.polygon = polygon;
      this.getPath(this.polygon.getPath(), this.polygon.getArea());
      this.map.setFitView([polygon]);
      this.marker = new AMap.Text({
        text: this.addForm2Copy.name,
        zIndex: 777,
        anchor: "center",
        style: {
          "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
          "font-weight": "bold",
          padding: "14px",
        },
        position: path[0],
      });
      this.marker.setMap(this.map);
      this.$nextTick(() => {
        this.nearbyArearChange(this.list);//展示全部附近站点
      })
    },
    /** 点击清除 */
    clear () {
      if (this.map) {
        this.map.clearMap();
      }
      // if (this.form.area) this.form.area = ''
    },
    // 拖拽选址方法
    positionPicker () {
      let vm = this;
      // 初始化地图
      this.map = null;
      let map = new AMap.Map(this.id, {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      document.getElementById(this.id).addEventListener("mousewheel", (e) => {
        let zoom = map.getZoom();
        if (e.deltaY > 0) {
          map.setZoom(--zoom);
          return;
        }
        map.setZoom(++zoom);
      });
      // 地图拖动的时候清空文本标记Marker Text
      map.on("dragging", function () {
        vm.clear();
      });
      map.on("zoomchange", function () {
        vm.clear();
      });
      vm.map = map;
      // 如果地图是初始化进来的
      if (vm.areaContent == "") {
        var keywords = vm.station;
        AMap.plugin("AMap.PlaceSearch", function () {
          var autoOptions = {
            city: vm.station,
          };
          var placeSearch = new AMap.PlaceSearch(autoOptions);
          placeSearch.search(keywords, function (status, result) {
            vm.map.setCenter([
              result.poiList.pois[0].location.lng,
              result.poiList.pois[0].location.lat,
            ]);
          });
        });
      } else {
        // 判断如果areaContent有值，说明是修改编辑进来的
        vm.map.setCenter([
          JSON.parse(vm.areaContent)[0].lng,
          JSON.parse(vm.areaContent)[0].lat,
        ]);
        vm.map.setZoom(16);
        let t = setTimeout(() => {
          vm.$emit(
            "positionVariableFun",
            JSON.stringify([
              {
                lng: JSON.parse(vm.areaContent)[0].lng,
                lat: JSON.parse(vm.areaContent)[0].lat,
              },
            ]),
            vm.addForm2Copy.name,
            vm.addForm2Copy.detialAddress
          );
          vm.clear();
          vm.marker = new AMap.Text({
            text: vm.addForm2Copy.name,
            zIndex: 777,
            anchor: "center",
            offset: new AMap.Pixel(0, -60),
            style: {
              "box-shadow": "0px 2px 8px 0px rgba(199,200,201,0.5)",
              "font-weight": "bold",
              "font-size": "15px",
              padding: "14px",
            },
            position: [
              JSON.parse(vm.areaContent)[0].lng,
              JSON.parse(vm.areaContent)[0].lat,
            ],
          });
          vm.marker.setMap(vm.map);
          clearTimeout(t);
        }, 1400);
      }
      // 地图拖拽
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          zIndex: 666,
          iconStyle: {
            //自定义外观
            url: require("@/assets/images/home/from_icon2.png"),
            ancher: [15, 30],
            size: [30, 39],
          },
        });
        vm.pickerPosition.on("success", function (positionResult) {
          vm.centerPointMap = [
            positionResult.position.lng,
            positionResult.position.lat,
          ];
          vm.clear();
          vm.marker = new AMap.Text({
            text: positionResult.regeocode.pois[0].name,
            zIndex: 777,
            anchor: "center",
            offset: new AMap.Pixel(0, -60),
            style: {
              "box-shadow": "0px 2px 8px 0px rgba(199,200,201,0.5)",
              "font-weight": "bold",
              "font-size": "15px",
              padding: "14px",
            },
            position: [
              positionResult.position.lng,
              positionResult.position.lat,
            ],
          });
          vm.$emit(
            "positionVariableFun",
            JSON.stringify([
              {
                lat: positionResult.position.lat,
                lng: positionResult.position.lng,
              },
            ]),
            "",
            positionResult.regeocode.addressComponent.district +
            positionResult.regeocode.addressComponent.township +
            positionResult.regeocode.addressComponent.street +
            positionResult.regeocode.addressComponent.streetNumber
          );
          vm.marker.setMap(vm.map);
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    //选中附近区域列表
    nearbyArearChange (val) {
      //清除区域label
      this.markerOverlay && this.map.remove(this.markerOverlay);
      this.markerOverlay = [];
      this.overlayGroup && this.overlayGroup.clearOverlays();
      let overlayGroup = new AMap.OverlayGroup();
      val.forEach((item) => {
        item.arr = [];
        JSON.parse(item.areaContent).forEach((element) => {
          item.arr.push([element.lng, element.lat]);
        });
      });
      this.$nextTick(() => {
        //绘制附近区域-覆盖物集群
        val.forEach((item) => {
          let pl = new AMap.Polygon({
            path: item.arr,
            strokeColor: "#5F9EA0",
            strokeWeight: 4,
            strokeOpacity: 0.8,
            fillOpacity: 0.3,
            fillColor: "#5F9EA0",
            zIndex: 50,
          });
          overlayGroup.addOverlay(pl);
          //设置marker的label
          this.markerOverlay.push(
            new AMap.Text({
              text: item.name,
              zIndex: 77,
              anchor: "center",
              offset: new AMap.Pixel(0, -10),
              style: {
                "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                padding: "6px",
              },
              position: [
                JSON.parse(item.areaContent)[0].lng,
                JSON.parse(item.areaContent)[0].lat,
              ],
            })
          );
        });
        this.overlayGroup = overlayGroup;
        this.map.add(overlayGroup);
        this.map.add(this.markerOverlay);
        this.map.setFitView();
      });
    },
  },
  mounted () {
    this.map = new AMap.Map(this.id, {
      mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
      zoom: 14,
      resizeEnable: true,
      expandZoomRange: true,
      showIndoorMap: false,
    });
    // 1区域  2固定点
    if (this.locationType == 1) {
      //过滤掉固定点，不需要展示在附近区域内
      const areaList = this.areaContentList.filter(item => item.areaMode === 0);
      if (this.title == "修改") {
        const index = areaList.findIndex(
          (i) => i.id == this.addForm2Copy.id
        );
        this.list = this.deepClone(areaList);
        this.list.splice(index, 1);
      } else {
        this.list = areaList;
      }
      this.areaValue = [...this.list];
      this.loadmap();
    } else if (this.locationType == 2) {
      this.positionPicker();
    }
  },
  destroyed () {
    this.map.destroy();
  },
  watch: {
    searchInput: {
      handler (val) {
        if (val) this.searchFun(val);
      },
    },
    locationType: {
      handler (val) {
        this.$nextTick(() => {
          if (val == 2) {
            this.clear();
            this.map.destroy();
            this.positionPicker();
          } else {
            this.pickerPosition.stop();
            this.pickerPosition = null;
            this.loadmap();
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.editMap {
  height: 100%;
  width: 100%;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    .right {
      display: flex;
      .tips {
        margin-right: 10px;
      }
    }
    .searchBox {
      width: 260px;
      height: 28px;
      position: relative;
      margin-left: 10px;
      /deep/.el-input {
        input {
          padding-left: 30px;
        }
      }
      i {
        position: absolute;
        font-size: 20px;
        z-index: 2057;
        top: 4px;
        left: 6px;
      }
      .searchContent {
        max-height: 320px;
        overflow-x: hidden;
        overflow-y: auto;
        background: #ffffff;
      }
      .address {
        width: calc(100% - 8px);
        display: inline-block;
        cursor: pointer;
        padding: 4px 4px 0 4px;
        line-height: 24px;
      }
      .addressDetail {
        width: calc(100% - 8px);
        display: inline-block;
        border-bottom: 1px solid #f4f4f4;
        cursor: pointer;
        padding: 0 4px 4px 4px;
        line-height: 24px;
        font-size: 12px;
        color: #afafaf;
      }
    }
  }
  .definedMap {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.content-address:hover {
  background: #f4f4f4;
}
</style>
